export interface HeadingProps {
  id?: string;
  title: string | number;
  subtitle?: string | string[];
  children?: string;
  size?: 'small' | 'standard' | 'large';
}

export default function Heading({ title, id, subtitle, children, size = 'standard' }: HeadingProps) {
  let titleClass = 'antialiased text-gray-900 break-all';
  let subtitleClass = '';

  switch (size) {
    case 'small':
      titleClass += ' leading-none text-lg font-semibold tracking-wide text-black opacity-60';
      subtitleClass = ' text-sm text-gray-600';
      break;
    case 'standard':
      titleClass += ' leading-7 text-lg sm:text-xl font-bold sm:font-semibold tracking-wide';
      subtitleClass = 'text-sm text-gray-600 mt-0.5';
      break;
    case 'large':
      titleClass += ' leading-7 text-2xl sm:text-4xl font-bold tracking-normal';
      subtitleClass = 'text-base font-light mt-1 sm:mt-3 text-gray-700';
      break;
  }

  let heading = (
    <h1 className={titleClass} id={id}>
      {title}
    </h1>
  );

  let subtitles = [];

  if (Array.isArray(subtitle)) {
    for (let i = 0; i < subtitle.length; i++) {
      subtitles.push(<p key={i}>{subtitle[i]}</p>);
    }
  } else if (children) {
    subtitles.push(<p key="subtitle">{children}</p>);
  } else if (subtitle) {
    subtitles.push(<p key="subtitle">{subtitle}</p>);
  }

  if (subtitles.length > 0) {
    return (
      <div>
        {heading}
        <div className={`${subtitleClass} space-y-3`}>{subtitles}</div>
      </div>
    );
  }

  return heading;
}
